<template>
<div>

  <div style="display: flex;margin-left: 290px;margin-top: 50px;">
      <li v-for="(item, index) of title" :key="index" @click="changeLiSytle(item)" :style="item.titleClass">{{ item.titieName }}</li>
  </div>
  <div class="sectionItem">
        <div v-for="(item, index) in singerList" :key="index">
            <div class="sectionItem-item" :style="{backgroundImage:'url('+getUrl(item.pic)+')',backgroundSize:'100%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', backgroundRepeat: 'no-repeat'}" 
            @mouseenter="item.showImgFlag = !item.showImgFlag" 
            @mouseleave="item.showImgFlag = !item.showImgFlag">
                <img src="../../assets/img/playMusic.png" v-if="item.showImgFlag == true" style="width: 5vw;height: 5vw;" alt="">
            </div>
            <div style="text-align: center;background-color: #ffffff;">{{ item.name }}</div>
        </div>
    </div>


</div>
  
</template>

<script>
import getInfoRequest from '../../api/info/index.js'
import { mixins } from "../../mixins/index";
export default {
  mixins: [mixins],
  data(){
    return {
      singerList: [],
      title: [
        {titieName: "全部歌手",titleClass: {color: "#000000", fontWeight: "bolder"}},
        {titieName: "男歌手",titleClass: {}},
        {titieName: "女歌手",titleClass: {}},
        {titieName: "组合歌手",titleClass: {}},
      ]
    }
  },
  created(){
    this.getSingerList();
  },
  methods: {
    getSingerList(){
            getInfoRequest.getSingerPage(null).then(res =>{
                if(res == null || res.code != 0) {
                    this.$message.error("数据查询失败")
                    return false;
                } else {
                    for(var item of res.data){
                        item.showImgFlag = false;
                    }
                    this.singerList = res.data;

                    console.log(res.data)
                }
            }).catch(err => {
                console.log("err=> ", err)
            })
            
      },
      changeLiSytle(items){
          var arr = [];
          for(var item of this.title){
            var obj = {};
            
            if(items.titieName != item.titieName){
              obj.titleClass = {}
            } else {
              obj.titleClass = {color: "#000000", fontWeight: "bolder"}
            }
            obj.titieName = item.titieName;
            arr.push(obj);
          }
          this.title = arr;
          //发送命令查询歌手集合
          var req = {}
          if(items.titieName == null || items.titieName == "全部歌手") {
              req = {
                sex: ""
              }
          } else if(items.titieName == "男歌手"){
            req = {
              sex: 1
            }
          }else if(items.titieName == "女歌手"){
            req = {
              sex: 0
            }
          }else if(items.titieName == "组合歌手"){
            req = {
              sex: 2
            }
          }
          getInfoRequest.getSingerPage(req).then(res =>{
                if(res == null || res.code != 0) {
                    this.$message.error("数据查询失败")
                    return false;
                } else {
                    for(var item of res.data){
                        item.showImgFlag = false;
                    }
                    this.singerList = res.data;
                }
            }).catch(err => {
                console.log("err=> ", err)
            })
        }
  }

}
</script>

<style scoped>

.sectionItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-flow: wrap;
        margin-left: 15%;
        margin-right: 14%;
    }
    @media screen and (max-width: 700px) {
        .sectionItem{
            margin-left: 10px;
            justify-content: center;
        }
    }


    .sectionItem-item{
        width: 12vw;
        height: 12vw;
        min-width: 150px;
        min-height: 150px;
        margin: 1vw;
        border-radius: 15px;    
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    li {
      float: left;
      font-size: 20px;
      color: #858a8a;
      list-style-type: none;
      margin-left: 20px;
    }


</style>