<template>
  <div class="song-audio">
    <audio id="player" 
        :src="this.$store.state.HOST +  this.$store.state.url"
        controls="controls"
        preload=true
        @canplay="startPlay"
        @ended="ended"
        @play="playMusic"
        @pause="stopMusic"
        @timeupdate="timeupdate"
        v-if="url != ''"
    >
    </audio>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
export default {

    name: 'songAudio',
    data() {
        return {
            songUrl: "",
        }
    },
    computed: {
        ...mapGetters([
        "id",           //歌曲id
        "url",          //歌曲地址
        "songs",        //歌曲集合
        "isPlay",       //是否播放中
        "listOfSongs",  //当前歌曲列表
        "curTime",      //当前音乐的播放位置
        "changeTime",   //指定播放时间
        "autoNext",     //用于自动触发播放下一首
        "volune",        //音量
        "isDrag"
        ])
    },

    methods: {
        startPlay(){
            let player = document.getElementById("player");
            if(player == null){
                return false;
            }
            //记录播放时长
            this.$store.state.duration = player.duration;
            if(this.isPlay){
                player.play().catch((err)=>{
                })
            } else {
                player.pause();
            }  
        },
        ended(){
            this.$store.state.isPlay = false;            
            /* this.setIsPlay(false) */
            if(this.songs.length > 1) {
                this.$store.state.songs = this.songs.slice(1, this.songs.length);
            } else {
                this.$store.state.songs = [];
                this.$store.state.url = "";
                this.$store.state.id = "";
                this.$store.state.artist = "";
                this.$store.state.title = "";
                this.$store.state.curTime = 0;
                this.$store.state.picUrl = 0;
            }
   
        },

        stopMusic(){
            this.$store.state.isPlay = false; 
        },

        playMusic(){
            this.$store.state.isPlay = true; 
        },

        //音乐播放时记录音乐的播放位置
        timeupdate(){
            if(this.isDrag) {
                return;
            }
            let player = document.getElementById("player");
            this.$store.state.curTime = player.currentTime;
        }

    },
    watch: {
        isPlay(newVal, oldVal){            
            this.startPlay(); 
        },
        changeTime(){
            
            let player = document.getElementById("player");
            player.currentTime = this.$store.state.curTime = this.changeTime;
           
        },
        //改变音量
        volune(val){
            let player = document.getElementById("player");
            player.volume = val / 100;
        },
        //歌曲集合变化
        songs(newVal){
            this.$store.state.isPlay = false;
            if(this.songs != null && this.songs.length > 0){
                var obj = this.songs[0];
                this.$store.state.id = obj.id;
                this.$store.state.url = obj.url;
                this.$store.state.picUrl = obj.pic;
                this.$store.state.title = obj.songName;
                this.$store.state.artist = obj.name;
                this.$store.state.curTime = 0;
                this.$store.state.lyric = obj.lyric;
                this.$store.state.isPlay = true
            }
            
        }

    }
}
</script>

<style>
    .song-audio{
        display: none;
    }
</style>