<template>
  <div>
    <headerSwiper></headerSwiper>
    <div class="section-title">歌手</div>
    <div class="sectionItem">
        <div v-for="(item, index) in singerList" :key="index" @click="goSingerListItem(item)">
            <div class="sectionItem-item" :style="{backgroundImage:'url('+getUrl(item.pic)+')',backgroundSize:'100%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'}" 
            @mouseenter="item.showImgFlag = !item.showImgFlag" 
            @mouseleave="item.showImgFlag = !item.showImgFlag">
                <img src="../../assets/img/playMusic.png" v-if="item.showImgFlag == true" style="width: 5vw;height: 5vw;" alt="">
            </div>
            <div style="text-align: center;background-color: #ffffff;">{{ item.singerName }}</div>
        </div>
    </div>
    

    <div class="section-title">歌单</div>
    <div class="sectionItem">
        <div v-for="(item, index) in songList" :key="index" @click="getSongListItem(item)">
            <div class="sectionItem-item" :style="{backgroundImage:'url('+getUrl(item.pic)+')',backgroundSize:'100%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'}" 
            @mouseenter="item.showImgFlag = !item.showImgFlag" 
            @mouseleave="item.showImgFlag = !item.showImgFlag">
                <img src="../../assets/img/playMusic.png" v-if="item.showImgFlag == true" style="width: 5vw;height: 5vw;" alt="">
            </div>
            <div style="text-align: center;background-color: #ffffff;">{{ item.songListStyle }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import headerSwiper from '../../components/headerSwiper/index.vue'
import getInfoRequest from '../../api/info/index.js'
import { mixins } from "../../mixins/index";
export default {
    name: 'Info',
    mixins: [mixins],
    data(){
        return {
            singerList: [],
            songList: [],
            pageNum: 1,
            pageSize: 10
        }
    },
    created(){
        this.getSingerList();
        this.getSongList()
    },
    methods: {
       
        getSingerList(){
            const getSingerListInfoRequest = {
            pageNum: this.pageNum,
            pageSize: this.pageSize
            }
            getInfoRequest.getSingerPage(getSingerListInfoRequest).then(res =>{
                if(res == null || res.code != 0) {
                    this.$message.error("数据查询失败")
                    return false;
                } else {
                    for(var item of res.data){
                        item.showImgFlag = false;
                    }
                    this.singerList = res.data;
                }
            }).catch(err => {
                console.log("err=> ", err)
            })
        },
        getSongList(){
            const getSongListInfoRequest = {
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            getInfoRequest.getSongListPage(getSongListInfoRequest).then(res =>{
                if(res == null || res.code != 0) {
                    this.$message.error("数据查询失败")
                    return false;
                } else {
                    for(var item of res.data){
                        item.showImgFlag = false;
                    }
                    this.songList = res.data;
                }
            }).catch(err => {
                console.log("err=> ", err)
            })
        },

        goSingerListItem(item){
            //this.$store.state.singerInfo = item;
            window.sessionStorage.setItem("singerInfo", JSON.stringify(item));
            this.$router.push("/singerListItem");
        },

        getSongListItem(item){
            this.$router.push("/songListItem");
            //this.$store.state.songListInfo = item;
            window.sessionStorage.setItem("songListInfo", JSON.stringify(item));
            console.log("item is " + JSON.stringify(item))
        },
    },
    components: {
        headerSwiper
    }
}
</script>

<style>
    .section-title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 35px;
    }

    .sectionItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-flow: wrap;
        margin-left: 15%;
        margin-right: 14%;
    }
    @media screen and (max-width: 700px) {
        .sectionItem{
            margin-left: 10px;
            justify-content: center;
        }
    }


    .sectionItem-item{
        width: 12vw;
        height: 12vw;
        min-width: 150px;
        min-height: 150px;
        margin: 1vw;
        border-radius: 15px;    
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


</style>