import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import vuex from 'vuex';
import store from './store/index.js'
import router from '../src/router/index.js'

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(vuex)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bus = this; //安装全局事件总线，$bus就是当前应用的vm
  }

  
}).$mount('#app')
