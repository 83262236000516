<template>
  <div class="sign">
      <el-image
        style="width: 40%; height: 40%;background-color: #2AA2EF"
        :src="require('../../assets/img/loginLogo.png')"
        >
      </el-image>
      <div class="sign-right">
        <div style="display: flex; justify-content: center;padding-bottom: 20px;padding-top: 20px">
          <h2>用户注册</h2>
        </div>

        <el-form label-width="80px" :model="ruleForm">
          <el-form-item label="用户名" required>
            <el-input v-model="ruleForm.username" style="width: 300px" aria-required=""></el-input>
          </el-form-item>
          <el-form-item label="密码" required>
            <el-input v-model="ruleForm.password" type="password" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="ruleForm.sex">
              <el-radio label="0">女</el-radio>
              <el-radio label="1">男</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机号" required>
            <el-input v-model="ruleForm.phoneNum" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="ruleForm.email" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="生日" required>
            <el-col :span="11">
              <el-form-item prop="date1">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.birth" style="width: 300px;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="签名">
            <el-input v-model="ruleForm.introduction" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="地区">
            <el-input v-model="ruleForm.location" style="width: 300px"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 150px">取消</el-button>
            <el-button type="primary" style="width: 150px" @click="addUser">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
      
      
  </div>
</template>

<script>
import userRequest from "../../api/user/index.js";
export default {
  data() {
      return {
        ruleForm: {
          username: '',
          password: '',
          sex: '',
          phoneNum: '',
          email: '',
          birth: '',
          introduction: '',
          location: '',
        },
      };
    },
    methods: {
      addUser(){
        userRequest.addUser(this.ruleForm).then(res => {
          if(res.code != null && res.code == 0){
            //新增用户成功
            this.$message.success("新增用户成功")
            this.$router.push("index")
          } else {
            this.$message.error("新增用户失败")
          }
        }).catch(err => {
          this.$message.error("新增用户失败")
        })
      },

    }
  }
</script>

<style  scoped>
  .sign {
    background-color: #E8ECF1;
  }

  .sign-right{
    float: right;
    width: 500px;
    margin-right: 20%;
    margin-top: 30px;
    background-color: #ffffff;
  }


  .el-input__inner {
    width: 20px;
  }

</style>