<template>
  <div id="app">
    <headerLogo></headerLogo>
    <router-view></router-view>
    <songAudio></songAudio>
    <scrollTop></scrollTop>
    <footerPage></footerPage>
    <playBar></playBar>

    
  </div>
</template>

<script>

import headerLogo from './components/headerLogo/index.vue'
import scrollTop from './components/scrollTop/index.vue'
import footerPage from './components/footer/index.vue'
import songAudio from './components/songAudio/index.vue'
import playBar from './components/playBar/index.vue'
export default {
  name: 'App',
  components: {
    headerLogo,
    scrollTop,
    footerPage,
    songAudio,
    playBar,
  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    
  }
  body{
    min-width: 700px;
    height: 100%;
  }
</style>
