<template>
    <div>
        <info></info>
    </div>
    
    
    
</template>

<script>
import info from './info/index.vue'
export default {

    components: {
        info
    }

}
</script>

<style scope>
 
</style>