import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = {
    state: {
        HOST: '/musicserver',  //后端请求路径
        songLists: [],
        songs: [],
        allSongs: [],
        id: "",
        url: "",
        isPlay: false,
        playButtonUrl: '#icon-bofang',  //播放时候的图标
        duration: 0,        //音乐时长
        curTime: 0,       //当前音乐的播放位置
        changeTime: 0,   //指定播放时间
        title: '',       //歌名，
        artist: '',    //歌手名
        picUrl: '',     //歌曲图片
        autoNext: true,  //用于自动触发播放下一首
        lyric: "",    //未处理的歌词数据
        tempList: {},  //单个歌单信息
        listIndex: null,   //当前歌曲在歌单中的位置
        volune: 50,          //音量
        isDrag: false,
        loginShow: true,
        userAvator: "",
        singerInfo: {},
        songListInfo: {}
    },

    getters: {
        id: state => state.id,
        isPlay: state=> state.isPlay,
        duration: state=> state.duration,
        curTime: state=> state.curTime, 
        url: state=> state.url,
        HOST:  state=> state.HOST,
        picUrl:  state=> state.picUrl,
        serachName: state=> state.serachName,
        changeTime: state=> state.changeTime,
        songs: state=> state.songs,
        title: state=> state.title,
        artist: state=> state.artist,
        isDrag: state=> state.isDrag,
        volune: state=> state.volune,
        allSongs: state=> state.allSongs,
        lyric: state=> state.lyric,
        loginShow: state=> state.loginShow,
        userAvator: state=> state.userAvator,
        singerInfo: state=> state.singerInfo,
        songListInfo: state=> state.songListInfo,


    },

    mutations: {
        /* setIsPlay(isPlay) {
            state.isPlay = isPlay
        },
        setUrl(isUrl) {
            state.isUrl = isUrl
        },
        setId(isId) {
            state.isId = isId
        }, */

        setSongUrl(state, songUrl){
            state.songUrl = songUrl;
        },

        setIsPlay(state, isPlay){
            state.isPlay = isPlay;
        }
    }

}

const config = new Vuex.Store(store)
export default config;