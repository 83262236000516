<template>
    <div style="background-color: #E8ECF1;height: 500px;">
        <div class="search">
            <div style="display: flex;justify-content: space-around;">
                <div class="search-item-songList" @click="serachSong()" :style="currentView == 'serachSongs' ? {borderBottom: '3px solid black'} : null">歌曲</div>
                <div class="search-item-songList" @click="serachSongList()" :style="currentView == 'serachSongLists' ? {borderBottom: '3px solid black'} : null">歌单</div>
            </div>
            <!-- 歌单 -->
            <div v-if="currentView == 'serachSongLists'" style="overflow: auto;height: 400px;">
                <el-table
                    :data="songList"
                    @row-click="goSongListItem"
                    style="width: 100%">
                    <el-table-column
                        align="center"
                        label="标题"
                        width="300">
                        <template slot-scope="scoped">
                            <p style="overflow: auto;"> {{ scoped.row.title }} </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="style"
                        label="风格"
                        width="300">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="专辑">
                        <template slot-scope="scoped">
                            <p style="overflow: auto;height: 100px;text-align: center;"> {{ scoped.row.introduction }} </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 歌曲 -->
            <div v-if="currentView == 'serachSongs' " style="overflow: auto;height: 400px;">
                <el-table
                    @row-click="playMusic"	
                    :data="songs"
                    style="width: 100%">
                    <el-table-column
                        align="center"
                        prop="songName"
                        label="歌曲名"
                        width="400">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="name"
                        label="歌手"
                        width="400">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="introduction"
                        label="专辑">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        
    </div>
    
  
</template>

<script>
import songListRequest from '../../api/songList/index.js';
import songRequest from '../../api/song/index.js';
import { mapMutations } from 'vuex';
export default {
    name: 'serachPage',
    data(){
        return {
            serachName: "",
            currentView: "serachSongs",
            songs: [],
            songList: []
        }
    },


    created() {
        this.$bus.$on("serachMusicChange", (serachname) => {
            this.serachName = serachname;
            this.serachSong();
        })
    },
    beforeDestroy(){
        this.$bus.$emit("clearMusicName");
    },
    methods: {
        ...mapMutations(['setSongUrl', 'setIsPlay']),
        serachSong() {
            this.currentView = 'serachSongs';
            if(this.songs.length > 0){
                this.songs = [];
            }
            const requestParam = {
                'songName':  this.serachName 
            }
            if(requestParam.songName == null || requestParam.songName ==  '') {
                return;
            }
            songRequest.getSongs(requestParam).then(res => {
                if(res == null || res.code != 0){
                    this.$message.error("系统错误")
                } else {
                    if(res.data == [] || res.data == null){
                        this.$message.success("未找到对应的歌曲")
                    } else {
                        var itemLists = [];
                        for(var item of res.data){
                            const itemList = item.name.split("-");
                            const songListItem = {
                                "id": item.id,
                                "name": itemList[0],
                                "songName": itemList[1],
                                "introduction": item.introduction,
                                "lyric": item.lyric,
                                "url": item.url,
                                "pic": item.pic,
                            }
                            itemLists.push(songListItem);
                        }
                        this.songs = itemLists
                    }
                }
            }).catch(err => {
                this.$message.error("系统错误")
            })
        },

        serachSongList() {
            this.currentView = 'serachSongLists';
            const requestParam = {
                'songListName': this.serachName
            }
            if(this.serachName == null ||this.serachName == '') return ;
            this.songList = [];
            songListRequest.getSongList(requestParam).then(res => {
                if(res == null || res.code != 0){
                    this.$message.error("系统错误")
                } else {
                    if(res.data == [] || res.data == null){
                        this.$message.success("未找到对应的歌单")
                    } else {
                        this.songList = res.data;
                    }
                }
            }).catch(err => {
                this.$message.error("系统错误")
            })
        },

        playMusic(row){
            var songLists = [];
            for(var i = 0; i < this.songs.length; i++){
                if(this.songs[i].id === row.id){
                    this.$store.state.allSongs = this.songs;
                    var songLists = this.songs.slice(i, this.songs.length);
                    this.$store.state.songs = songLists;
                    break;
                }
            }   
        },

        goSongListItem(row){
            console.log(row)
            const obj = {
                id: row.id,
                pic: row.pic,
                songListStyle: row.style,
                showImgFlag: true
            }
            window.sessionStorage.setItem("songListInfo", JSON.stringify(obj));
            this.$router.push("/songListItem");
        }
    },
}
</script>

<style scoped>
    .search {
        position: relative;
        border-radius: 15px;
        margin-left: 20%;
        margin-right: 20%;
        margin-top: 30px;
        background-color: #ffffff;

    }

    .search-item-songList {
        font-size: 1.5rem;
        line-height: 50px;
        cursor: pointer;
       
    } 



</style>