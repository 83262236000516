import request from "../../request/index.js"

export function addUser(data){
    return request({
        url: "/consumer/add",
        method: "POST",
        data
    })
}

export function login(data){
    return request({
        url: "/consumer/login",
        method: "POST",
        data
    })
}

export function getUserInfo(params){
    return request({
        url: "/consumer/detail",
        method: "get",
        params
    })
}

export function findUserCollectSongs(params){
    return request({
        url: "/consumer/findUserCollectSongs",
        method: "get",
        params
    })
}



export default { addUser, login, getUserInfo, findUserCollectSongs}

