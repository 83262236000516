import request from '../../request/index.js'


export function getSongList(params){
    return request({
        method: 'get',
        url: '/songList/findSongListVByName',
        params
    })
}


export default {getSongList}