import request from "../../request/index"


export function getSongCollected(params){
    return request({
        method: "get",
        url: "/song/isCollected",
        params
    })
}

export function updateSongCollected(data){
    return request({
        method: "post",
        url: "/song/collectSong",
        data
    })
}

export default {getSongCollected, updateSongCollected}