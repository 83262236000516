<template>
    
    <div  class="scroll-top" @click="returnTop()">
        <img src="../../assets/img/scrollTop.png" width="100%" alt="" >
    </div>
</template>

<script>
export default {
    name: 'ScrollTop',
    data(){
        return {
            showFlag: true
        }
    },
    methods: {
        returnTop() {
            document.documentElement.scrollTop = document.body.scrollTop = 0
        },
    }
}
</script>

<style scoped>
    .scroll-top {
        position: fixed;
       /*  right: 3%; */
        width: 40px;
        height: 40px;
        border-radius: 6px 6px 6px 6px;
        right: 1%;
        top: 70%;
        /* background-color: #ffffff; */
        box-shadow: -4px 0 6px -2px #888888,
                    0 -4px 6px -2px #888888,
                    0 4px 6px -2px #888888,
                    4px 0 6px 2px #888888;
    }
</style>