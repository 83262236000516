import request from '../../request/index.js'


export function getSingerPage(params){
    return request({
        method: 'get',
        url: '/client/getSingerPage',
        params
    })
}



export function getSongListPage(params){
    return request({
        method: 'get',
        url: '/client/getSongListPage',
        params
    })
}

export default {getSingerPage, getSongListPage}
