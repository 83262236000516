import request from "../../request/index"


export function getSingerInfo(params){
    return request({
        method: "get",
        url: "/singer/detailSingerInfo",
        params
    })
}


export default { getSingerInfo, }