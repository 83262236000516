//导航栏
const navMsg = [
    {name: '首页', path: '/'},
    {name: '歌单', path: '/songList'},
    {name: '歌手', path: '/singer'},
    {name: '我的音乐', path: 'myMusic'},
    {name: '', path: 'serach'},
    {name: '登录', path: 'login'},
    {name: '注册', path: 'sign'},
]


export default navMsg