<template>
    <div class="footer">
        <p class="footer-item" style="padding-bottom: 20px;">关于|帮助|条款|反馈</p>
        <p class="footer-item">CopyRight @2020</p>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
    .footer {
        margin-top: 80px;
        background-color: #E8ECF1;
        height: 70px;
        width: 100%;
        margin-bottom: 62px;       
    }

    .footer-item {
        font-size: 15px;
        font-weight: bold;
        /* line-height: 50px; */
        text-align: center;
    }
</style>