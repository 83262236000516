<template>
    <div class="navbar">
        <div class="navbar-img" @click="goHome()">
            <img src="../../assets/img/musciImg.png" alt="">
            <span>music</span>
        </div>
        <div class="navbar-menu">
            <el-menu 
                class="el-menu-vertical-demo" mode="horizontal"
                :default-active="onRoutes"
                @select="activeOnRoutes"
                background-color="#ffffff"
                text-color="#888888"
                active-text-color="#20a0ff"
                router
            >
                <template v-for="item in navMsg ">
                    <template>
                        <el-menu-item :index="item.path" :key="item.index">
                            <template v-if="item.path == 'serach'">
                                <div @click="goSearch()">
                                    <el-input placeholder="搜索音乐" 
                                        v-model="serachMusic"
                                        >
                                        <el-button slot="append" icon="el-icon-search" ></el-button>
                                    </el-input>
                                </div>
                            </template>
                            <template>
                                <template v-if="(item.path == 'login' || item.path == 'sign')  && $store.state.loginShow == true" >
                                    {{ item.name }}
                                </template>
                               
                                <template v-if="item.path != 'login' && item.path != 'sign'">
                                    {{ item.name }}
                                </template>
                            </template>
                        </el-menu-item>
                    </template>
                </template>

                <template v-if="$store.state.loginShow != true" >
                    <el-dropdown>
                        <div style="margin-top: 10px;margin-left: 20px;">
                            <el-avatar :size="40" src="https://empty" @error="true">
                            <img :src="getUserUrl($store.state.userAvator)"/>
                            </el-avatar>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>个人中心</el-dropdown-item>
                            <el-dropdown-item @click.native="logout()">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-menu>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import navMsgs from '../../assets/data/header.js';
import { mixins } from '@/mixins';
export default {
    name: 'myHeader',
    mixins: [ mixins ],
    data(){
        return {
            navMsg: [],
            serachMusic: ''
        }
    },
    computed: {
        ...mapGetters(['loginShow', 'userAvator'])
    },
    created(){
        if(window.sessionStorage.getItem("user") != null) {
            //代表用户已经登录
            this.$store.state.loginShow = false;
        }
        this.navMsg = navMsgs;
        this.$bus.$on("clearMusicName", () => {this.serachMusic = ''})
    },
 
    computed: {
        ...mapGetters(['loginShow']),
        onRoutes(){
            return window.sessionStorage.getItem("routerPath") ? window.sessionStorage.getItem("routerPath") : this.navMsg[0].path
        },
        
    },

    methods: {
    
        goHome(){
            if(this.$route.path != "/" && this.$route.path != "/index"){
                this.$router.push("/index");
            }
            
        },

        goSearch() {
            if(this.$route.path != "/serach"){
                this.$router.push('/serach');
            }
            this.$bus.$emit("serachMusicChange", this.serachMusic);
            
        },

        goSign(){
            if(this.$route.path != "/sign"){
                this.$router.push('/sign');
            }
        },

        goLogin(){
            if(this.$route.path != "/login"){
                this.$router.push('/login');
            }
        },

        logout(){
            window.sessionStorage.removeItem("user");
            window.sessionStorage.removeItem("userId");
            this.$store.state.loginShow = true;
            location.reload()
        },

        activeOnRoutes(key, keyPath){
            if(keyPath == "myMusic") {
                //判断用户是否登录
                const userId = window.sessionStorage.getItem("userId");
                if(userId == null || userId == ""){
                    this.$message.error("用户未登录");
                    this.$router.push("login");
                    return false;
                } else {
                    window.sessionStorage.setItem("routerPath", keyPath)
                }
            } else {
                window.sessionStorage.setItem("routerPath", keyPath)
            }
            
        }
        
    },

    watch: {
        loginShow(newVal){
            if(newVal){
                this.navMsg = navMsgs
            } else {
                this.navMsg = this.navMsg.slice(0,5);
            }
           
        }
    }



}
</script>

<style scoped>
    .navbar {
        height: 55px;
        box-shadow: 3px 3px 3px #888888;
    }
    .navbar-img img{
        padding-top: 3px;
        width: 45px;
        height: 45px;
        float: left;
        margin-left: 40px;
    }
    .navbar-img span{
        padding-top: 10px;
        align-self: center;
        font-size: 25px;
        font-weight: bold;
        float: left;
    }
    .navbar-menu {
        float: left;
        margin-left: 10px;
    }

    .el-menu.el-menu--horizontal {
        border-bottom: none;
    }

    .el-menu--horizontal>.el-menu-item {
        height: 50px;
        margin-left: 50px;
    }

    .el-menu-item {
        background-color: #ffffff !important;
    }

    /* .el-menu--horizontal > .el-menu-item {
        width: 50%;
        border-bottom: none;
        height: 1rem;
        line-height: 0.5rem;
        text-align: center;
    } */

    .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom-color: transparent;
        border-bottom: none;
    }

    .el-menu--horizontal>.el-menu-item {
        border-bottom-color: transparent;
        border-bottom: none;
    }

    

</style>