<template>
  <div style="background-color: #E8ECF1;">
    <div class="search">
        <div>
            <div class="lyric-item" @click="serachSong()">歌词</div>
            <hr style="width:80%;margin-left: auto; margin-right: auto;border: 2px solid #000; border-radius: 10em">
            <div class="lyric-li">
                <ul>
                    <li v-for="(item,index) in lyr" :key="index" style="list-style-type: none;" :style="getStyle(item)">{{ item[1] }}</li>
                </ul>
            </div>
            
        </div>    
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "lyric",
    data(){
        return{
            "lyr": "",
            "songStyle": {
                "color": "red",
                "fontSize": "20px"
            }
            /* "songStyle": {
                "color": "red",
                "fontSize": "20px"
            } */
        }
    },
    computed: {
        ...mapGetters([
            "lyric",
            "curTime"
        ])
    },
    created(){
        this.lyr = this.parseLyric(this.lyric);
    },
    methods: {
         //解析歌词
         parseLyric(text){
            let lines = text.split("\n");  //将歌词换行分解为数组
            let pattern = /\[\d{2}:\d{2}.(\d{3}|\d{2})]/g; // 时间格式正确表达式
            let result = [];                    //返回值
            //对于歌词格式不对的直接返回
            if(/\[].+\]/.test(text)){
                return [[0, text]]
            }
            while(!pattern.test(lines[0])){
                lines = lines.slice(1);
            }
            //遍历每一行行程一个带两个元素的数组， 第一个元素是时间， 第二个元素是歌词
            for(let item of lines){
                let time = item.match(pattern); //存储前面的时间段
                let value = item.replace(pattern, " ");
                for(let item1 of time){
                    //获取分钟和秒
                    let t = item1.slice(1, -1).split(":");
                    console.log("@@@分 ", parseInt(t[0], 10))
                    if(value != ''){
                        result.push([parseInt(t[0], 10) * 60 + parseFloat(t[1]), value])
                    }
                }
            }
            result.sort(function(a, b){
                return a[0] - b[0];
            });
            return result;
        },

        getStyle(item){
            /* console.log(item[0]) */
           /*  console.log(parseInt(item[0]));
            console.log(parseInt(this.curTime)); */
            if(item[0] == this.curTime) {
                return this.songStyle;
            } else {
                return "";
            }
            
        }
    },
    watch: {
        lyric(newVal){
            this.lyr = this.parseLyric(newVal);
        }
    }
}
</script>

<style scoped>

    .search {
        position: relative;
        border-radius: 15px;
        margin-left: 20%;
        margin-right: 20%;
        margin-top: 30px;
        background-color: #ffffff;

    }


    .lyric-item {
        display: flex;
        justify-content: center;
        font-size: 30px;
        margin-bottom: 10px;
        width:100%
    } 

    .lyric-li {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }
</style>