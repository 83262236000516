//第一步导入axios
import axios from "axios"
import { Notification } from 'element-ui'
// 引入nprogress相关的js和css
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'



//获取当前域名
let curWwwPath = window.document.location.href;
let ProHeader = curWwwPath.split("//")[0] + "//";
let IP = curWwwPath.split("//")[1].split("/")[0];
//获取请求服务器的路径，并写入缓存
sessionStorage.setItem('URL', ProHeader + IP);
//  sessionStorage.setItem('URL', 'https://loc3.qvcloud.net/');
let getUrl = sessionStorage.getItem('URL');
// console.log("getUrl", getUrl);


//第二步 我们可以声明一个新的常量axios 我们可以配置一些基础 公共的路径配置   比如说baseURL timeout请求失败超时报错
// console.log("process.env", process.env);
const service = axios.create({
    //baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : 'oamapi/', // api 的 base_url //如果配置了环境变量就可以直接写/api,
    baseURL: process.env.NODE_ENV === 'production' ? getUrl + '/musicserver' : '/musicserver', // api 的 base_url //如果配置了环境变量就可以直接写/api,
    //跨域请求是否提供凭据信息(cookie、HTTP认证及客户端SSL证明等),也可以简单的理解为，当前请求为跨域类型时是否在请求中协带cookie。
    //默认值为false。在获取同域资源时设置 withCredentials 没有影响。 true：在跨域请求时，会携带用户凭证  false：在跨域请求时，不会携带用户凭证；返回的 response 里也会忽略 cookie
    withCredentials: false,
    timeout: 30000, //请求超时
    // headers是被发送的自定义请求头，请求头内容需要根据后端要求去设置，这里我们使用本项目请求头。
    // headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    // }
})


//第三步 设置请求拦截
//新的常量axios service.拦截器.请求.使用===》 里头有两个参数 一个成功的回调函数  一个失败的回调函数
service.interceptors.request.use(
    (config) => {
        NProgress.start(); //开启进度条
        //请求发出前会立即自动执行的回调函数
        // 统一设置请求头(设置请求头头数据的格式，看服务器是)
        // config.headers['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('token');
        //console.log("config", config)
        return config
    }, err => {
        //请求的时候如果发生错误了， 这边直接给它抛出错误就行
        // throw new Error(err)抛出的是一个红色的报错方便我们查看寻找
        NProgress.done(); //停止进度条
        Notification.error({
            title: 'loading...',
            duration: 5000
        })
        throw new Error(err)
    }
)

//第四步 设置响应拦截
service.interceptors.response.use(response => {
    //我们每次成功发送一个请求 它都会有响应的 参数也是两个
    //一般成功之后可以清除或关闭loading动画 还可以判断一些状态码
    NProgress.done(); //停止进度条
    // console.log("router", router);
    // return false
    return response.data
}, err => {
    console.log(err)
    NProgress.done(); //停止进度条
    //统一处理完成之后，这个错误可以让后面继续处理
    // return Promise.reject(new Error('发送ajax请求失败')) //后面想继续处理这个错误，返回失败的promise
    //也可以不让后面继续处理这个错误，中断promise链
    return new Promise(() => {}) //返回的是pending状态的promise，代表中断promise链，后期也就没办法处理了
        // return Promise.reject(err)
})


//导出  导出这个模块
export default service;