export const mixins = { 
    methods: {
        getUrl(url) {
            if(url == null || url == '') return `${this.$store.state.HOST}` + '/img/singerPic/singerDefaultImg.png';
            return `${this.$store.state.HOST}${url}` ;
        },

        getUserUrl(url) {
            if(url == null || url == '') return null;
            return `${this.$store.state.HOST}${url}` ;
        },

        getSongListItem(url) {
            if(url == null || url == '') return null;;
            return `${this.$store.state.HOST}${url}` ;
        },

        formatSeconds(value){
            let theTime = parseInt(value);
            let result = '';
            let hour = parseInt(theTime / 1000);
            let minute = parseInt((theTime / 60) % 60);
            let second = parseInt(theTime  % 60);
            if(hour > 0){
                if(hour < 10){
                    result = '0' + hour + ":";
                }else {
                    result = hour + ":";
                }
            }
            if(minute > 0) {
                if(minute < 10){
                    result += '0' + minute + ":";
                } else {
                    result += minute + ":";
                }
            } else {
                result += "00:";
            }

            if(second > 0) {
                if(second < 10){
                    result += '0' + second;
                } else {
                    result += second;
                }
            } else {
                result += "00";
            }
            return result;
        },


        //解析歌词
        parseLyric(text){
            let lines = text.split("\n");  //将歌词换行分解为数组
            let pattern = /\[\d{2}:\d{2}.(\d{3}|\d{2})]/g // 时间格式正确表达式
            let result = [];                    //返回值
            //对于歌词格式不对的直接返回
            if(/\[].+\]/.test(text)){
                return [[0, text]]
            }
            while(!pattern.test(lines[0])){
                lines = lines.slice(1);
            }
            //遍历每一行行程一个带两个元素的数组， 第一个元素是时间， 第二个元素是歌词
            for(let item of lines){
                let time = item.match(pattern); //存储前面的时间段
                let value = item.replace(pattern, " ");
                for(let item1 of time){
                    let t = item1.slice(1, -1).split(":");
                    if(value != ''){
                        result.push([parseInt(t[0], 10) * 60 + parseFloat(t[1]), value])
                    }
                }
            }
            result.sort(function(a, b){
                return a[0] - b[0];
            });
            return result;
        }
    },
}