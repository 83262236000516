<template>
    <div>
      <div style="width: 100%;background-color: #95D1F5">
          <div style="height:90px">
              <div class="songListItem-img">
                  <el-avatar shape="square" :size="180" fit="contain" :src="getUrl(singerInfo.singer.pic)"></el-avatar>
              </div>
          </div>
         
          <div class="songListItem-context">
              <div style=" padding-left: 50px;padding-top: 150px">
                  <div style="width: 300px;float: left;height: 300px;overflow:auto;">
                      <p>性别： {{singerInfo.singer.sex == "0" ? "女" : "男"}}</p>
                      <p>生日: {{singerInfo.singer.birth}}</p>
                      <p>故乡： {{singerInfo.singer.name}}</p>
                  </div>   
              </div>
           
              <div class="songListItem-table">
                  <div style="width: 100%;font-size: 25px;text-align: center;font-weight: 2px">
                      <p>歌单</p>
                      <el-table
                          ref="singleTable"
                          :data="singerInfo.songList"
                          @row-click="playMusic"
                          highlight-current-row
                          
                          style="width: 100%">
                          <el-table-column
                          type="index"
                          align="center"
                          width="50">
                          </el-table-column>
                          <el-table-column
                          property="songName"
                          align="center"
                          label="歌曲名"
                          width="120">
                          </el-table-column>
                          <el-table-column
                          property="singerName"
                          align="center"
                          label="歌手"
                          width="460">
                          </el-table-column>
                          <el-table-column
                          property="album"
                          align="center"
                          label="专辑">
                          </el-table-column>
                      </el-table>
                  </div>
              </div>    
          </div>  
      </div>
    </div>

</template>
  
  <script>
  import { mixins } from "../../mixins/index";
  import singerListItem from "../../api/SingerListItem/index"
  export default {
      name: "SingerListItem",
      mixins: [ mixins ],
      created(){
          this.getSingerInfo();
      },
      data() {
        return {
          singerInfo: {
            singer: {
                id: "",
                name: "",
                sex: "",
                pic: "",
                birth: "",
                location: "",
                introduction: "",
            },
            songList: []
          },
          tableData: [],
          singerLocation: "",
          commentList: [],
          pageSize: 5,
          pageNum: 1
        }
      },
  
      methods: {
         getSingerInfo(){
            const singerRequest = {
                singerId: JSON.parse(window.sessionStorage.singerInfo).id
            }
            singerListItem.getSingerInfo(singerRequest).then(res => {
                if(res != null && res.code == 0){
                    this.singerInfo = res.data;
                    const list = [];
                    for(var item of this.singerInfo.songList){
                        var items = item.name.split("-");
                        item.songName = items[1];
                        item.singerName = items[0];
                        list.push(item)
                    }
                    this.singerInfo.songList = list;
                }else {
                    this.$message.error("系统错误")
                }
            }).catch(err => {
                this.$message.error("系统错误")
            })
         },
         playMusic(row){
            var songLists = [];
            for(var item of this.singerInfo.songList){
                const songListItem = {
                                "id": item.id,
                                "name": item.name.split("-")[0],
                                "songName": item.name.split("-")[1],
                                "introduction": item.introduction,
                                "lyric": item.lyric,
                                "url": item.url,
                                "pic": item.pic,
                            }
                songLists.push(songListItem)
            }
            for(var i = 0; i < songLists.length; i++){
                if(songLists[i].id === row.id){
                    this.$store.state.allSongs = songLists;
                    var list = songLists.slice(i, songLists.length);
                    this.$store.state.songs = list;
                    break;
                }
            }
        },
      }
  }
  </script>
  
  <style scoped>
      .songListItem-img{
          display: flex;
          padding-top: 35px;
          padding-left: 50px;
      }
  
      .songListItem-context {
          width:100%;
          /* height:600px; */
          padding-bottom: 100px;
          background-color: #E8ECF1;
      }
  
      .songListItem-title {
          padding-top: 50px;
          padding-left: 400px
      }
  
      .songListItem-table {
          width:60%; 
          display:flex;
          margin-left: 400px; 
          margin-top: -100px;
          background-color: #ffffff;
          border-radius: 20px;
      }
  
  </style>