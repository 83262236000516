import request from "../../request/index"


export function getSongListItemList(id){
    return request({
        method: "GET",
        url: "/songList/findSongBySongList/" + id
    })
}

export default {getSongListItemList, }