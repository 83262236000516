import Vue from 'vue'

import VueRouter from "vue-router"


import indexPage from "../page/index.vue"
import songListPage from "../page/songList/index.vue"
import singerPage from "../page/singer/index.vue"
import myMusic from "../page/myMusic/index.vue"
import serachPage from "../page/serach/index.vue"
import lyric from "../page/lyric/index.vue"
import login from "../page/Login/index.vue"
import sign from "../page/Sign/index.vue"
import singerListItem from "../page/SingerListItem/index.vue"
import songListItem from "../page/SongListItem/index.vue"

Vue.use(VueRouter);
const routes = [

    {
        path: "/index",
        name: "index",
        component: indexPage
    },
    {
        path: "/songList",
        name: "songList",
        component: songListPage

    },
    {
        path: "/singer",
        name: "singer",
        component: singerPage

    },
    {
        path: "/myMusic",
        name: "myMusic",
        component: myMusic

    },
    {
        path: "/serach",
        name: "serach",
        component: serachPage
    },
    {
        path: "/lyric",
        name: "lyric",
        component: lyric
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/sign",
        name: "sign",
        component: sign
    },
    {
        path: "/singerListItem",
        name: "singerListItem",
        component: singerListItem
    },
    {
        path: "/songListItem",
        name: "songListItem",
        component: songListItem
    },
    {
        path: "*",
        component: indexPage
    },

]


//重写push方法
const originPush = VueRouter.prototype.push; // 把官方的push方法暂存到originPush这个变量里面
VueRouter.prototype.push = function push(location) { // 参数是调用push方法传进来的参数
    return originPush.call(this, location).catch(() => {});
};
//或者重写replace方法
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originReplace.call(this, location).catch(() => {})
}

const router = new VueRouter({
    mode: 'hash',
    routes: routes,
})



export default router