<template>
  <div>
    <div style="width: 100%;background-color: #95D1F5">
        <div style="height:90px">
            <div class="songListItem-img">
                <el-avatar shape="square" :size="180" fit="contain" :src="getSongListItem(songListInfo.pic) == '' ? getSongListItem(songListInfo.pic)  : require('../../assets/img/songListItem.png')"></el-avatar>
            </div>
        </div>
       
        <div class="songListItem-context">
            <h2 class="songListItem-title">{{ songListInfo.songListStyle }}</h2>
            <div style=" padding-left: 50px;padding-top: 70px">
                <h2>简介:</h2>
                <div style="width: 300px;float: left;height: 300px;overflow:auto;">
                    <p>{{songListInfo.introduction}}</p>
                </div>   
            </div>
         
            <div class="songListItem-table">
                <div style="width: 100%;font-size: 25px;text-align: center;font-weight: 2px">
                    <p>歌单</p>
                    <el-table
                        ref="singleTable"
                        :data="tableData"
                        @row-click="playMusic"
                        highlight-current-row
                        
                        style="width: 100%">
                        <el-table-column
                        type="index"
                        align="center"
                        width="50">
                        </el-table-column>
                        <el-table-column
                        property="songName"
                        align="center"
                        label="歌曲名"
                        width="120">
                        </el-table-column>
                        <el-table-column
                        property="singerName"
                        align="center"
                        label="歌手"
                        width="460">
                        </el-table-column>
                        <el-table-column
                        property="album"
                        align="center"
                        label="专辑">
                        </el-table-column>
                    </el-table>
                <p style="padding-top: 50px">评论</p>
                <hr style="border: 1px solid #000;border-radius: 2px; text-align: center">
                <div>
                    <div style="display: flex; padding-left: 20px;padding-top: 20px; width: 90%">
                        <el-avatar shape="square" :size="50" fit="contain" :src="getSongListItem(songListInfo.pic) == '' ? getSongListItem(songListInfo.pic)  : require('../../assets/img/songListItem.png')"></el-avatar>
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="textarea"
                            style="padding-left: 10px">
                        </el-input>
                    </div>
                    <el-button type="primary" style="margin-top: 20px;margin-left: 70%;" @click="addComment">提交</el-button>
                    
                </div>
                <div style="font-size: 15px;margin-bottom:40px; ">
                    <p style="padding-right: 83%;">精彩评论：共{{ totleCount }}条评论</p>
                    <div style="display: flex;padding-left: 20px;padding-top: 20px; width: 90%" v-for="(item,index) in commentList" :key="index">
                        <el-avatar shape="square" :size="50" fit="contain" :src="getSongListItem(songListInfo.pic) == '' ? getSongListItem(songListInfo.pic)  : require('../../assets/img/songListItem.png')"></el-avatar>
                        <div style="text-align: left;margin-left: 20px">
                            <p>{{ item.username }}</p>
                            <p>{{ item.createTime }}</p>
                            <p>{{ item.content }}</p>
                        </div>
                        
                    </div>
                    <hr>
                </div>
                <div class="block">
                <el-pagination
                    layout="prev, pager, next"
                    :total="totleCount"
                    @current-change="findCommentList"
                    @size-change="findCommentList"
                    >
                </el-pagination>
                </div>
            </div>    
        </div>  
    </div>
</div>
    
  </div>
</template>

<script>
import { mixins } from "../../mixins/index";
import songListItemRequest from '../../api/SongListItem/index'
import commentRequest from "../../api/comment/index"
export default {
    name: "SongListItem",
    mixins: [ mixins ],
    created(){
        this.songListInfo = JSON.parse(window.sessionStorage.songListInfo);
        this.getSongListItemList(this.songListInfo.id);
        this.findCommentList(1);
    },
    data() {
      return {
        songListInfo: {},
        tableData: [],
        textarea: "",
        totleCount: 0,
        commentList: [],
        pageSize: 5,
        pageNum: 1
      }
    },

    methods: {
        getSongListItemList(id){
            songListItemRequest.getSongListItemList(id).then(res => {
                if(res != null && res.code == 0){
                    const list = res.data.list;
                    var response = [];
                    const obj1 = {
                            songListStyle: res.data.songList.style,
                            introduction: res.data.songList.introduction,
                        }
                    this.songListInfo = obj1;    
                    for(var item of list){
                        var songName = item.name.split("-")[1];
                        var singerName = item.name.split("-")[0];
                        item.songName = songName;
                        item.singerName = singerName;
                        item.album = "";        
                        response.push(item);
                    }
                    this.tableData = response;
                    console.log(this.tableData);
                } else {
                    this.$message.error("系统异常");
                }
            }).catch(err => {
                this.$message.error("系统异常");
            })
        },
        playMusic(row){
            console.log(row)
            var songLists = [];
            for(var item of this.tableData){
                const songListItem = {
                                "id": item.id,
                                "name": item.name.split("-")[0],
                                "songName": item.name.split("-")[1],
                                "introduction": item.introduction,
                                "lyric": item.lyric,
                                "url": item.url,
                                "pic": item.pic,
                            }
                songLists.push(songListItem)
            }
            for(var i = 0; i < songLists.length; i++){
                if(songLists[i].id === row.id){
                    this.$store.state.allSongs = songLists;
                    var list = songLists.slice(i, songLists.length);
                    this.$store.state.songs = list;
                    break;
                }
            }
        },

        findCommentList(page){
            const comment = {
                type: 1,
                songListId: this.songListInfo.id,
                content: this.textarea,
                startPage: page,
                pageSize: 5
            };
            commentRequest.getCommentList(comment).then(res => {
                if(res != null && res.code == 0){
                    this.totleCount = res.data.count;
                    this.commentList = res.data.list;
                } else {
                    this.$message.error("系统错误");
                }
            }).catch(err => {
                this.$message.error("系统错误");
            })
        },
        addComment(){
            if(this.textarea == '') this.$message.error("评论内容为空");
            const comment = {
                type: 1,
                songListId: JSON.parse(window.sessionStorage.songListInfo).id,
                content: this.textarea,
                userId: window.sessionStorage.userId,
            };
            commentRequest.addComment(comment).then(res => {
                if(res != null && res.code == 0){
                    this.$message.success("新增成功");
                    this.findCommentList(this.pageNum)
                } else {
                    this.$message.error("新增失败");
                }
            }).catch(err => {
                this.$message.error("新增失败");
            })
        }
    }
}
</script>

<style scoped>
    .songListItem-img{
        display: flex;
        padding-top: 35px;
        padding-left: 50px;
    }

    .songListItem-context {
        width:100%;
        /* height:600px; */
        background-color: #E8ECF1;
    }

    .songListItem-title {
        padding-top: 50px;
        padding-left: 400px
    }

    .songListItem-table {
        width:60%; 
        display:flex;
        margin-left: 400px; 
        margin-top: -100px;
        background-color: #ffffff;
        border-radius: 20px;
    }

</style>