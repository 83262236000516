<template>
    <div class="swiper">
        <div class="swiper-carousel">
            <el-carousel :interval="4000" type="card" height="280px">
                <el-carousel-item v-for="item in imgList" :key="item">
                    <img :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
    
  
</template>

<script>
export default {
    name: "headerSwiper",
    data(){
        return {
            imgList: []
        }
    },
    created() {
        this.addImgList();
    },

    methods: {
        addImgList(){
            for(var i = 1; i < 9; i++){
                var imgUrl = require("../../assets/swiper/" + i + ".jpg")
                this.imgList.push(imgUrl)
            }
        }
    }
}
</script>
<style scoped>

    .swiper {
        margin-top: 4px;
        background-color: #E8ECF1;
    }

    .swiper-carousel{
        width: 75%;
        margin: auto;
       
    }

</style>