<template>
    <div>
       
      <div style="width: 100%;background-color: #95D1F5">  
        <div style="height:90px">
            <div class="songListItem-img">
                <el-avatar :size="180" fit="contain" :src="getUrl(userInfo.useravator)"></el-avatar>
            </div>
        </div>  
          <div class="songListItem-context">
              <div style=" padding-left: 50px;padding-top: 150px">
                  <div style="width: 300px;float: left;height: 300px;overflow:auto;">
                      <p>昵称 {{userInfo.username}}</p>
                      <p>性别： {{userInfo.usersex == "0" ? "女" : "男"}}</p>
                      <p>生日: {{userInfo.userBirth}}</p>
                      <p>故乡： {{userInfo.userLocation}}</p>
                  </div>   
              </div>
           
              <div class="songListItem-table">
                  <div style="width: 100%;font-size: 25px;text-align: center;font-weight: 2px">
                      <p>我的收藏</p>
                      <el-table
                          ref="singleTable"
                          :data="userCollectSongs"
                          @row-click="playMusic"
                          highlight-current-row
                          
                          style="width: 100%">
                          <el-table-column
                          type="index"
                          align="center"
                          width="50">
                          </el-table-column>
                          <el-table-column
                          property="songName"
                          align="center"
                          label="歌曲名"
                          width="120">
                          </el-table-column>
                          <el-table-column
                          property="singerName"
                          align="center"
                          label="歌手"
                          width="460">
                          </el-table-column>
                          <el-table-column
                          property="album"
                          align="center"
                          label="专辑">
                          </el-table-column>
                      </el-table>
                  </div>
              </div>    
          </div>  
      </div>
      </div>

</template>
  
  <script>
  import { mixins } from "../../mixins/index";
  import singerListItem from "../../api/SingerListItem/index"
  import consumerRequest from "../../api/user/index"
  export default {
      name: "SingerListItem",
      mixins: [ mixins ],
      created(){
          this.getSingerInfo();
          this.getUserInfo();
      },
      data() {
        return {
          userInfo: {
            username: "",
            usersex: "",
            userBirth: "",
            userLocation: "",
            userIntroduction: "",
            useravator: ""
          },
          userCollectSongs: [],
          tableData: [],
          singerLocation: "",
          commentList: [],
          pageSize: 5,
          pageNum: 1
        }
      },
  
      methods: {

        getUserInfo(){
            const req = {
                consumerId: window.sessionStorage.getItem("userId")
            }
            consumerRequest.getUserInfo(req).then(res => {
                if(res != null && res.code == 0) {
                    this.userInfo.username = res.data.username
                    this.userInfo.usersex = res.data.sex
                    this.userInfo.userBirth = res.data.birth
                    this.userInfo.userLocation = res.data.location
                    this.userInfo.userIntroduction = res.data.introduction
                    this.userInfo.useravator = res.data.avator
                } else {
                    this.$message.error("系统错误")
                }
            }).catch(err => {
                this.$message.error("系统错误")
            })
        },


         getSingerInfo(){
            if(window.sessionStorage.getItem("userId") == null || window.sessionStorage.getItem("userId") == ""){
                this.$message.error("用户未登录");
                this.$router.push("login");
                return;
            }
            const singerRequest = {
                consumerId: window.sessionStorage.getItem("userId")
            }
            consumerRequest.findUserCollectSongs(singerRequest).then(res => {
                if(res != null && res.code == 0){
                    this.userCollectSongs = res.data;
                    const list = [];
                    for(var item of this.userCollectSongs){
                        var items = item.name.split("-");
                        item.songName = items[1];
                        item.singerName = items[0];
                        list.push(item)
                    }
                    this.userCollectSongs = list;
                }else {
                    this.$message.error("系统错误")
                }
            }).catch(err => {
                this.$message.error("系统错误")
            })
         },
         playMusic(row){
            var songLists = [];
            for(var item of this.userCollectSongs){
                const songListItem = {
                                "id": item.id,
                                "name": item.name.split("-")[0],
                                "songName": item.name.split("-")[1],
                                "introduction": item.introduction,
                                "lyric": item.lyric,
                                "url": item.url,
                                "pic": item.pic,
                            }
                songLists.push(songListItem)
            }
            for(var i = 0; i < this.userCollectSongs.length; i++){
                if(songLists[i].id === row.id){
                    this.$store.state.allSongs = this.userCollectSongs;
                    var list = songLists.slice(i, this.userCollectSongs.length);
                    this.$store.state.songs = list;
                    break;
                }
            }
        },
      }
  }
  </script>
  
  <style scoped>
      .songListItem-img{
          display: flex;
          padding-top: 35px;
          padding-left: 50px;
      }
  
      .songListItem-context {
          width:100%;
          /* height:600px; */
          padding-bottom: 100px;
          background-color: #E8ECF1;
      }
  
      .songListItem-title {
          padding-top: 50px;
          padding-left: 400px
      }
  
      .songListItem-table {
          width:60%; 
          display:flex;
          margin-left: 400px; 
          margin-top: -100px;
          background-color: #ffffff;
          border-radius: 20px;
      }
  
  </style>