import request from "../../request/index"


export function getCommentList(params){
    return request({
        method: "get",
        url: "/comment/findAllComment",
        params
    })
}

export function addComment(data){
    return request({
        method: "post",
        url: "/comment/addComment",
        data
    })
}


export default {getCommentList, addComment}