<template>
  <div>
    <div style="display: flex;margin-left: 290px;margin-top: 50px;">
      <li v-for="(item, index) of title" :key="index" @click="changeLiSytle(item)" :style="item.titleClass">{{ item.titieName }}</li>
    </div>

    <div class="sectionItem">
          <div v-for="(item, index) in songList" :key="index" @click="getSongListItem(item)">
              <div class="sectionItem-item" :style="{backgroundImage:'url('+getUrl(item.pic)+')',backgroundSize:'100%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'}" 
              @mouseenter="item.showImgFlag = !item.showImgFlag" 
              @mouseleave="item.showImgFlag = !item.showImgFlag">
                  <img src="../../assets/img/playMusic.png" v-if="item.showImgFlag == true" style="width: 5vw;height: 5vw;" alt="">
              </div>
              <div style="text-align: center;background-color: #ffffff;">{{ item.style }}</div>
          </div>
      </div>
  
  
  </div>
    
  </template>
  
  <script>
  import getInfoRequest from '../../api/info/index.js'
  import { mixins } from "../../mixins/index";
  export default {
    mixins: [mixins],
    data(){
      return {
        songList: [],
        title: [
        {titieName: "全部歌单",titleClass: {color: "#000000", fontWeight: "bolder"}},
        {titieName: "华语",titleClass: {}},
        {titieName: "粤语",titleClass: {}},
        {titieName: "欧美",titleClass: {}},
        {titieName: "日韩",titleClass: {}},
        {titieName: "轻音乐",titleClass: {}},
        {titieName: "BMG", titleClass: {}},
        {titieName: "乐器", titleClass: {}},
      ]
      }
    },
    created(){
      this.getSongList();
    },
    methods: {
      getSongList(){
        getInfoRequest.getSongListPage(null).then(res =>{
            if(res == null || res.code != 0) {
                this.$message.error("数据查询失败")
                return false;
            } else {
                for(var item of res.data){
                    item.showImgFlag = false;
                }
                this.songList = res.data;
            }
        }).catch(err => {
            console.log("err=> ", err)
        })
    },
    changeLiSytle(items){
      var arr = [];
      for(var item of this.title){
        var obj = {};
        
        if(items.titieName != item.titieName){
          obj.titleClass = {}
        } else {
          obj.titleClass = {color: "#000000", fontWeight: "bolder"}
        }
        obj.titieName = item.titieName;
        arr.push(obj);
      }
      this.title = arr;
      //发送命令查询歌手集合
      var req = {}
      if(items.titieName == null || items.titieName == "全部歌单") {
          req = {
            style: ""
          }
      } else {
        req = {
          style: items.titieName
        }
      }
      getInfoRequest.getSongListPage(req).then(res =>{
            if(res == null || res.code != 0) {
                this.$message.error("数据查询失败")
                return false;
            } else {
                for(var item of res.data){
                    item.showImgFlag = false;
                }
                this.songList = res.data;
            }
        }).catch(err => {
            console.log("err=> ", err)
        })
    }
  }
}
  </script>
  
  <style scoped>
  
  .sectionItem{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-flow: wrap;
          margin-left: 15%;
          margin-right: 14%;
      }
      @media screen and (max-width: 700px) {
          .sectionItem{
              margin-left: 10px;
              justify-content: center;
          }
      }
  
  
      .sectionItem-item{
          width: 12vw;
          height: 12vw;
          min-width: 150px;
          min-height: 150px;
          margin: 1vw;
          border-radius: 15px;    
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      }

      li {
      float: left;
      font-size: 20px;
      color: #858a8a;
      list-style-type: none;
      margin-left: 20px;
    }

  
  </style>