<template>
    <div style="margin-top:80px;position: fixed;bottom: 0%;width: 100%; ">
        <div style="display: flex;margin-left: 20px;cursor: pointer;">
            <img src="../../assets/img/top.png" style="width: 20px;height: 20px;" alt="" v-if="show_arrow_bottom" @click="changeshowArrowBottomClose()">
            <img src="../../assets/img/bottom.png" style="width: 20px;height: 20px;padding-bottom: 10px;" alt="" v-if="!show_arrow_bottom" @click="changeshowArrowBottomOpen()">
        </div>
        <div class="playBar" :style="disPlayShow">
            <div style="width: 100%;">
                <div style="display:flex;justify-content: center;align-items: center;">
                    <img src="../../assets/img/previousSong.png" alt="" class="perviousSong" @click="previousSong">
                    <img src="../../assets/img/stop.png" alt="" v-if="isPlay" class="perviousSong" @click="togglePlay">
                    <img src="../../assets/img/startPlay.png" alt="" v-if="!isPlay" class="perviousSong" @click="togglePlay">
                    <img src="../../assets/img/nextSong.png" alt="" class="perviousSong" @click="nextSong">
                    <img :src="getUrl(picUrl)" alt="" class="songPic" @click="showLyric">
                    <p style="padding-left: 10px;padding-top: 10px;">{{ formatSeconds(curTime) }}</p>
                    <div style="float: left;padding-top: 10px;">
                        <div style="font-size: 10px;float: left;margin-bottom: 10px;margin-left: 15px;">{{ title }}</div>
                        <div style="font-size: 10px;float: right;margin-bottom: 10px;margin-right: 15px;">{{ artist }}</div>
                        <el-slider v-model="format" class="playBar_slider" :show-tooltip="false" @change="changeTime" @mousedown.native="isDragChange" @mouseup.native="unDragChange"></el-slider>
                    </div>
                    <p style="padding-top: 10px;">{{formatSeconds(duration)}}</p>
                    <div style="float: left;" @mouseenter="openVolune()" @mouseleave="openVolune()">
                        <el-slider v-model="liFormat" 
                        v-if="isShowVolune"
                        :vertical="true" 
                        style="position: relative;left: 40px;margin-top: -80px;" 
                        height="80px" 
                        @change="changeVolune()">
                        </el-slider> 
                        <img src="../../assets/img/volume.png" class="footer_img" alt="">
                    </div>
                    <img src="../../assets/img/collect.png" class="footer_img" v-if="isCollected" alt="" @click="changeCollect()">
                    <img src="../../assets/img/collected.png" class="footer_img" v-if="!isCollected" alt="" @click="changeCollect()">
                    <el-dropdown placement="top" @command="changeMusic">
                        <img src="../../assets/img/directory.png" class="footer_img" alt=""> 
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="item in allSongs" :key="item.id" :command="{item }">{{ item.songName }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>              
                </div>  
                
            </div>        
        </div>
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex'
import { mixins } from '@/mixins';
import collectRequest from "../../api/collect/index"
export default {
    name: "playBar",
    mixins: [mixins],
    data() {
        return {
            format: 0,
            liFormat: 50,
            colformat: 0,
            startTime: "00:00",
            endTime: "00:00",
            isCollected: true,
            isShowVolune: false,
            isChange: false,
            show_arrow_bottom: true,
            disPlayShow: {
                "display": ""
            }
        }
    },
    computed: {
        ...mapGetters ([
            "id",
            "url",
            "isPlay",
            "picUrl",
            "HOST",
            "title",
            "artist",
            "duration",
            "curTime",
            "isDrag",
            "allSongs"
        ])
    },

    mounted(){
        window.addEventListener("mouseup", this.unDragChange);
    },

    methods: {
        togglePlay(){
            if(this.url != null && this.url != ''){
                this.$store.state.isPlay = !this.$store.state.isPlay;
                return;
            }
            this.$message.error("没有音乐可以进行播放")
        },
        previousSong(){
            let arr = this.$store.state.allSongs;
            if(arr == null || arr.length == 0) {
                this.$message.error("没有音乐可以进行播放");
                return;
            }
            for(var i = 0; i < arr.length; i++){
                if(arr[i].id == this.id){
                    if(i === 0){
                        this.$message.error("目前为第一首歌曲");
                    } else {
                        this.$store.state.songs = arr.slice(i - 1, arr.length);
                    }
                }
            }
        },
        nextSong(){
            let arr = this.$store.state.allSongs;
            if(arr == null || arr.length == 0) {
                this.$message.error("没有音乐可以进行播放");
                return;
            }
            for(var i = 0; i < arr.length; i++){
                if(arr[i].id == this.id){
                    if(i === arr.length - 1){
                        this.$message.error("目前为最后一首歌曲");
                    } else {
                        this.$store.state.songs = this.$store.state.songs.slice(1, this.$store.state.songs.length);
                    }
                }
            }
        },


        changeTime(val){
            //修改时间触发
            if(this.url != null && this.url != ''){
                this.$store.state.changeTime = parseInt(val) / 100 * this.duration;   
            }else {
                this.format = 0;
            }
        },
        changeVolune(){
            this.$store.state.volune = this.liFormat;
        },
        openVolune(){
            this.isShowVolune = !this.isShowVolune
        },
        isDragChange(){
            this.$store.state.isDrag = true; 
        },
        unDragChange(){
            this.$store.state.isDrag = false; 
        },

        changeMusic(row){
            for(var i = 0; i < this.allSongs.length; i++){
                if(this.allSongs[i].id == row.item.id){
                    this.$store.state.changeTime = 0;
                    this.$store.state.songs = this.$store.state.allSongs.slice(i, 4);
                    break;
                }
            }
        },

        showLyric(){
            this.$router.push({name: "lyric"})
        },

        changeshowArrowBottomClose(){
            this.show_arrow_bottom = !this.show_arrow_bottom;
            console.log(this.disPlayShow);
            this.disPlayShow.display = "none"
        },

        changeshowArrowBottomOpen(){
            this.show_arrow_bottom = !this.show_arrow_bottom;
            this.disPlayShow.display = ""
        },

        singerCollected(newVal){
            //发送命令查询是否已经收藏
            const req = {
                id: newVal
            }
            collectRequest.getSongCollected(req).then(res => {
                if(res != null && res.code == 0) {
                    if(res.data){
                        this.isCollected = false;
                    } else {
                        this.isCollected = true;
                    }
                } else{
                    this.$message.error("系统错误")
                }
            }).catch(err => {
                this.$message.error("系统错误")
            })
        },

        changeCollect(){
            //判断用户是否登录
            var userId = window.sessionStorage.getItem("userId");
            if(userId == null || userId == ""){
                this.$message.error("用户未登录")
            } else {
                //进行更新
                const req = {
                    userId: userId,
                    songId: this.id,
                    type: 0
                }
                collectRequest.updateSongCollected(req).then(res => {
                    if(res != null && res.code == 0) {
                        this.isCollected = !this.isCollected;
                        this.$message.success("操作成功")
                    } else{
                        this.$message.error("系统错误")
                    }
            }).catch(err => {
                this.$message.error("系统错误")
            })
            }
        }
    },
    watch: {
        id(newVal) {
            //判断歌曲是否已被收藏
            this.singerCollected(newVal)
        },
        curTime(newVal){
            this.format = (newVal / this.duration) * 100
        },
    }
}
</script>

<style scoped>
    .playBar {
        height: 60px;
        background-color:#fafaf6;
        width: 100%;
        box-shadow: 3px -2px 0px 0px #888888;
    }

    .playBar_slider{
        width:500px;
        padding-top: 10px;
        padding-bottom: 5px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .songPic{
        width:45px;
        height:45px;
        padding-top: 10px;
        margin-left: 50px;
    }

    .perviousSong{
        width:25px;
        height:25px;
        padding-top: 10px;
        margin-left: 50px;
        float: left;
    }
    .footer_img{
        width:25px;
        height:25px;
        padding-top: 10px;
        margin-left: 50px;
        float: left;
    }
</style>