import request from '../../request/index.js';


export function getSongs(params){
    return request({
        method: 'get',
        url: '/song/findSongListBySongName',
        params

    })
}


export default { getSongs }